import Page from "components/Layout/Page";
import Tabs from "components/ui/Tabs";
import React, { useMemo, useState } from "react";
import NewActions from "./components/NewActions";
import { Table } from "components/ui/Table";
import Card from "components/Layout/Card";
import { PagedData, usePagedData } from "components/ui/Pagination/usePagedData";
import useBondsTableColumns from "./useBondsTableColumns";
import { BondDto } from "models/Bond";
import Payments from "./Payments/Payments"; 
import { userSelector } from "utils/redux/user/userSlice";
import { useSelector } from "react-redux";
import { Row } from "@tanstack/react-table";
import { useRouter } from "next/router";

interface Props {}

const Issuance: React.FC<Props> = () => {
  const router = useRouter();
  const user = useSelector(userSelector);
  const pagedData = usePagedData<BondDto>(
    ["bondsList", user?.id || ""],
    "bonds",
    !!user
  );
  const columns = useBondsTableColumns();
  const onRowClick = (row: Row<BondDto>) => router.push(`/bonds/${row.original.id}`);
  const tabs = useMemo(() => getTabs(pagedData, columns, onRowClick), [pagedData, columns]);
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  return (
    <Page title="Issuance">
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={(tab) => setActiveTab(tab)}
      />
    </Page>
  );
};

const getTabs = (pagedData: PagedData<BondDto>, columns: any, onRowClick: (row: Row<BondDto>) => void) => {
  return [
    {
      label: "New Actions",
      content: (
        <>
          <NewActions />
          <Card className="mt-6">
            <div className="p-4 text-xl font-semibold">Bonds</div>
            <Table {...pagedData} columns={columns} onRowClick={onRowClick} />
          </Card>
        </>
      ),
    },
    { label: "Payments", content: <Payments /> },
  ];
};

export default Issuance;
