import React from "react";
import PortfolioColombia from "screens/PortfolioColombia/PortfolioColombia";
import { userSelector } from "utils/redux/user/userSlice";
import { useSelector } from "react-redux";
import Issuance from "screens/Issuance/Issuance";

interface Props {}

const OverviewRoute: React.FC<Props> = () => {
  const user = useSelector(userSelector);

  return user?.type === 'issuer' ? <Issuance /> : <PortfolioColombia />;
};

export default OverviewRoute;
