import { AccountStatus } from "models/Status";

const getStatDotColor = (statisticType: AccountStatus | string) => {
  switch (statisticType) {
    case "Active":
      return "bg-green-400";
    case "Expired":
      return "bg-red-400";
    case "Closed":
      return "bg-red-400";
    case "Pending":
      return "bg-yellow-400";
    case "Passive":
      return "bg-yellow-400";
    case "Frozen":
      return "bg-purple-400";
    default:
      break;
  }
};

export default getStatDotColor;
