import React, { ReactNode } from "react";

import { AccountStatus } from "models/Status";
import Card from "../../Layout/Card";
import cx from "classnames";
import getStatDotColor from "./getStatDotColor";

interface Props {
  children?: ReactNode;
  label?: AccountStatus | string;
  value?: string | number | JSX.Element;
}

const StatsCard: React.FC<Props> = ({ children, label, value }) => {
  return (
    <Card className="h-[120px] min-w-[250px] py-3 px-4">
      {children ||
        (label && (
          <>
            <div className="flex justify-between">
              <div className="whitespace-nowrap text-lg font-semibold">
                {label}
              </div>
              <div
                className={cx("h-3 w-3 rounded-full", getStatDotColor(label))}
              />
            </div>

            <div className="mt-1 text-4xl font-bold">{value}</div>
          </>
        ))}
    </Card>
  );
};

export default StatsCard;
