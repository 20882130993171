import IconButton from "../IconButton";
import Link from "../Link";
import { PlusIcon } from "@heroicons/react/24/outline";
import Popover from "../Popover";
import React from "react";
import StatsCard from "./StatsCard";

export interface IPopoverTile {
  title: string;
  subtext?: string;
  onButtonClick?: () => void;
  popoverItems?: {
    text: string;
    onClick: () => void;
  }[];
}

export interface IStatTile {
  label: string;
  value: string | number | JSX.Element;
}

interface Props {
  tile: IPopoverTile;
}

const PopoverTile: React.FC<Props> = ({ tile }) => {
  return (
    <StatsCard>
      <div className="flex h-full items-center justify-between gap-6">
        <div className="self-start">
          <div className="text-lg font-semibold">{tile.title}</div>
          {tile.subtext && <div className="text-xs">{tile.subtext}</div>}
        </div>

        {tile.onButtonClick ? (
          <IconButton onClick={tile.onButtonClick}>
            <PlusIcon />
          </IconButton>
        ) : (
          <PopoverForTile tile={tile} />
        )}
      </div>
    </StatsCard>
  );
};

export const PopoverForTile: React.FC<Props> = ({ tile }) => {
  return (
    <Popover
      buttonEl={
        <IconButton>
          <PlusIcon />
        </IconButton>
      }
    >
      {tile.popoverItems?.map((pi) => (
        <Link
          key={pi.text}
          onClick={pi.onClick}
          className="w-44 rounded py-2 px-3 hover:bg-neutral-500/5"
        >
          {pi.text}
        </Link>
      ))}
    </Popover>
  );
};

export default PopoverTile;
