import Tiles from "components/ui/Tiles/Tiles";
import { NextRouter, useRouter } from "next/router";
import React from "react";

interface Props {}

const NewActions: React.FC<Props> = () => {
  const router = useRouter();
  const tiles = getTiles(router);
  return (
    <>
      <Tiles popoverTiles={tiles} />
    </>
  );
};

const getTiles = (router: NextRouter) => [
  {
    title: "Issue bond",
    subtext: "Issue security token",
    onButtonClick: () => router.push("/issuance/new"),
  },
  {
    title: "Distribute bond",
    subtext: "Distribute security token",
    onButtonClick: () => router.push("/issuance/distribute"),
  },
];

export default NewActions;
