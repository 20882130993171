import Button, { ButtonProps } from "./Button";

import React from "react";
import cx from "classnames";

const IconButton: React.FC<ButtonProps & { sizeClasses?: string }> = ({
  children,
  className,
  sizeClasses,
  ...props
}) => {
  const defaultSizeClasses =
    "max-h-[45px] min-h-[45px] min-w-[45px] max-w-[45px]";
  return (
    <Button
      {...props}
      variant="secondary"
      className={cx(
        "rounded-[999px] p-2 font-black",
        sizeClasses || defaultSizeClasses,
        className
      )}
    >
      {children}
    </Button>
  );
};

export default IconButton;
