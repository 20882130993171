import PopoverTile, { IPopoverTile, IStatTile } from "./PopoverTile";

import React from "react";
import StatsCard from "./StatsCard";
import api from "utils/api";
import cx from "classnames";
import { useQuery } from "@tanstack/react-query";

interface Props {
  popoverTiles?: IPopoverTile[];
  statsTiles?: IStatTile[];
  statsType?: "Investor" | "Issuer" | "Agent" | "Proposal";
  className?: string;
}

const Tiles: React.FC<Props> = ({
  statsType,
  popoverTiles,
  statsTiles,
  className,
}) => {
  const { data: statistics } = useQuery(
    [statsType, "statistics"],
    async () => {
      const { data } = await api.get<{ status: string; count: string }[]>(
        `/statistics/${statsType}`
      );

      return data;
    },
    { enabled: !!statsType }
  );

  return (
    <div className={cx("flex gap-5 pb-1", className)}>
      {popoverTiles?.map((pt) => (
        <PopoverTile key={pt.title} tile={pt} />
      ))}
      {statsTiles?.map((s) => (
        <StatsCard key={s.label} label={s.label} value={s.value} />
      ))}
      {statistics?.map((s) => (
        <StatsCard key={s.status} label={s.status} value={s.count} />
      ))}
    </div>
  );
};

export default Tiles;
